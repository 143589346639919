import config from '../settings/config';

const API_URL = 'https://' + config.api;
const WS_URL = 'wss://test.ec2.nevia-app.com:57679';
export const ROAD_URL = 'https://' + config.road;
export const ROAD_URL2 = 'https://' + config.road2;

export const paddedNumber = number => number <= 99 ? ('0' + number).slice(-2) : number;

export function Socket (url) {
  const socket = new WebSocket(WS_URL + url + '?x-nevia-auth=Bearer' + localStorage['login'].substring(7));
  socket.onopen = function (e) {
    console.log("SOCKET CONNECTED");
  }
  return socket;
}

export const timer = (time, data) => (
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, time);
  })
);

export const transformErrors = errors => {
  return errors.map(error => {
    if (error.schema.messages && error.schema.messages[error.name][error.argument]) {
      return {
        ...error,
        message: error.schema.messages[error.name][error.argument],
      };
    }
    return error;
  });
};

export const getRoadData = async (y, x, accuracy = 10, road = null) => {
  let data;

  let url = '?y=' + y + '&x=' + x + '&sade=' + accuracy;

  if (road != null) {
    url += '&tie=' + road
  }

  try {
    const fetchURL = ROAD_URL + url + '&ajorata=0,1,2';

    data = await (await window.fetch(fetchURL)).json();

    const roadData = data.features[0].properties;

    if (roadData.virheet != null || roadData.tie == null) {
      throw Error(roadData.virheet);
    }

    return {
      road: roadData.tie,
      part: roadData.osa,
      distance: roadData.etaisyys,
      y: roadData.y,
      x: roadData.x,
      address: roadData.katunimi ? roadData.katunimi + (roadData.katunumero ? (' ' + roadData.katunumero) : '') : null,
      city: roadData.kuntanimi,
      roadway: roadData.ajorata
    }
  } catch (error) {
    try {
      const fetchURL = ROAD_URL2 + '/muunna' + url;

      data = await (await window.fetch(fetchURL)).json();

      if (data.virhe != null) {
        return null;
      }

      return {
        road: data.tie,
        part: data.osa,
        distance: data.etaisyys,
        y: data.y,
        x: data.x
      };
    } catch (error) {
      console.log(error);
    }
  }

  return null;
}

export const getRoadCoordinates = async (road, part, distance) => {
  let data;

  let url = '?tie=' + road + '&osa=' + part + '&etaisyys=' + distance;

  try {
    const fetchURL = ROAD_URL + url;

    data = await (await window.fetch(fetchURL)).json();

    const roadData = data.features[0].properties;

    if (roadData.virheet != null) {
      throw Error(roadData.virheet);
    }

    return {
      y: roadData.y,
      x: roadData.x,
      address: roadData.katunimi ? roadData.katunimi + (roadData.katunumero ? (' ' + roadData.katunumero) : '') : null,
      city: roadData.kuntanimi
    }
  } catch (error) {
    try {
      const fetchURL = ROAD_URL2 + '/muunna' + url;

      data = await (await window.fetch(fetchURL)).json();

      if (data.virhe != null) {
        return false;
      }

      const point = data['alkupiste']['tieosoitteet'][0]['point'];

      return { y: point.y, x: point.x }
    } catch (error) {
      console.log(error);
    }
  }

  return null;
}

export function fetch (url, method = 'GET', data = null, type = null) {
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'x-nevia-auth': localStorage['login']
  };

  let fetchUrl = API_URL + '/nevipaver' + url;

  if (type != null) {
    headers = {
      'x-nevia-auth': localStorage['login']
    };
    fetchUrl = API_URL + '/observ/file';

    if (method === 'POST' || method === 'DELETE') {
      fetchUrl += '?path=' + url;
    }
    else {
      if (type !== 'image/jpg') {
        headers.Accept = type;
      }
      else {
        headers.Accept = 'image/jpeg';
      }
      fetchUrl += '?file=' + url;
    }
  }
  else if (data != null) {
    data = JSON.stringify(data);
  }

  return window.fetch(fetchUrl, {
    method: method,
    headers: headers,
    body: data,
    mode: 'cors',
  }).then(request => {
    if (!request.ok) {
      throw Error(request.status);
    }
    if (type) return request.blob();
    if (request.status !== 204) return request.json();
    return null;
  });
}

export function integerValue (value, defaultValue) {
  const parsedValue = parseInt(value, 10);

  if (isNaN(parsedValue)) {
    return defaultValue;
  }

  return parsedValue;
}


export function floatValue (value, defaultValue) {
  if (value == null) return defaultValue;
  const newValue = parseFloat(value);

  return newValue;
}

export function toRadians (angle) {
  return angle * (Math.PI / 180);
}

const Ca = 6378137.0;
const Cf = 1.0 / 298.257223563;
const Ck0 = 0.9996;
const Clo0 = toRadians(27.0);
const CE0 = 500000.0;
const Cn = Cf / (2.0 - Cf);
const CA1 = Ca / (1.0 + Cn) * (1.0 + (Math.pow(Cn, 2.0)) / 4.0 + (Math.pow(Cn, 4.0)) / 64.0);
const Ce = Math.sqrt((2.0 * Cf - Math.pow(Cf, 2.0)));
const Ch1p = 1.0 / 2.0 * Cn - 2.0 / 3.0 * Math.pow(Cn, 2.0) + 5.0 / 16.0 * Math.pow(Cn, 3.0) + 41.0 / 180.0 * Math.pow(Cn, 4.0);
const Ch2p = 13.0 / 48.0 * Math.pow(Cn, 2.0) - 3.0 / 5.0 * Math.pow(Cn, 3.0) + 557.0 / 1440.0 * Math.pow(Cn, 4.0);
const Ch3p = 61.0 / 240.0 * Math.pow(Cn, 3.0) - 103.0 / 140.0 * Math.pow(Cn, 4.0);
const Ch4p = 49561.0 / 161280.0 * Math.pow(Cn, 4.0);
const tm35fin_latitude_min = 6582464.0358;
const tm35fin_latitude_max = 7799839.8902;
const tm35fin_longitude_min = 50199.4814;
const tm35fin_longitude_max = 761274.6247;

export function toETRSTM35FIN (latitude, longitude) {
  const la = toRadians(latitude);
  const lo = toRadians(longitude);
  const Q = Math.asinh(Math.tan(la)) - Ce * Math.atanh(Ce * Math.sin(la));
  const be = Math.atan(Math.sinh(Q));
  const nnp = Math.atanh(Math.cos(be) * Math.sin(lo - Clo0));
  const Ep = Math.asin(Math.sin(be) * Math.cosh(nnp));
  const E1 = Ch1p * Math.sin(2.0 * Ep) * Math.cosh(2.0 * nnp);
  const E2 = Ch2p * Math.sin(4.0 * Ep) * Math.cosh(4.0 * nnp);
  const E3 = Ch3p * Math.sin(6.0 * Ep) * Math.cosh(6.0 * nnp);
  const E4 = Ch4p * Math.sin(8.0 * Ep) * Math.cosh(8.0 * nnp);
  const nn1 = Ch1p * Math.cos(2.0 * Ep) * Math.sinh(2.0 * nnp);
  const nn2 = Ch2p * Math.cos(4.0 * Ep) * Math.sinh(4.0 * nnp);
  const nn3 = Ch3p * Math.cos(6.0 * Ep) * Math.sinh(6.0 * nnp);
  const nn4 = Ch4p * Math.cos(8.0 * Ep) * Math.sinh(8.0 * nnp);
  const E = Ep + E1 + E2 + E3 + E4;
  const nn = nnp + nn1 + nn2 + nn3 + nn4;
  const y = CA1 * E * Ck0;
  const x = CA1 * nn * Ck0 + CE0;

  if (y < tm35fin_latitude_min || y > tm35fin_latitude_max ||
    x < tm35fin_longitude_min || x > tm35fin_longitude_max) {
    return { x: 0, y: 0 };
  }

  return { x: x, y: y };
}
