import React from 'react';
import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import {
  Login,
  Logout,
  LoginReducer,
  MenuReducer,
  MessageReducer,
  NoticeReducer,
  ConfirmReducer,
  ContractSelectReducer,
  ConstructionSiteSelectReducer
} from 'components';
import TrafficSignReducer from '../trafficSignPage/Reducer';

import App from './Application.js';
import TrafficSignPage from '../trafficSignPage/TrafficSignPage.js';


export const store = createStore(
  combineReducers({
    login: LoginReducer,
    menu: MenuReducer,
    message: MessageReducer,
    notice: NoticeReducer,
    confirm: ConfirmReducer,
    constructionSiteSelect: ConstructionSiteSelectReducer,
    contractSelect: ContractSelectReducer,
    trafficSign: TrafficSignReducer
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

const AppWithLocation = ({ store }) => {
  const location = useLocation();

  return <App store={store} location={location} />;
};


const Root = () => (
  <Provider store={store}>
    <span id="timer" />
    <BrowserRouter>
      <AppWithLocation store={store}/>
      <Routes>
        <Route path='/login' element={<Login store={store} />} />
        <Route path='/logout' element={<Logout store={store} />} />
        <Route path='/' element={<TrafficSignPage store={store} />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);

export default Root;