import React from 'react';
import { connect } from 'react-redux';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import { showNotice, showMessage, showConfirm, TopContractAndSiteSelect,
         MapTiles } from 'components'
import { addTrafficSign, changeTrafficSign, removeTrafficSign,
   addTrafficSigns } from './Actions';
import { fetch, Socket, paddedNumber, timer, toETRSTM35FIN, integerValue, getRoadData, getRoadCoordinates } from '../utils';
import { fromJS } from 'immutable';
import './TrafficSignPage.css';

let newCategoryCurrent ;


const TrafficSignSelect = props => {
  const ownCategorySelected = props.selectedCategory !== '' && isNaN(props.selectedCategory);

  return (
    <div id='traffic-sign-select-container'>
      <div>
        <label id='label-category-select' htmlFor='input-category-select'>Näytä:</label>
        <select id='input-category-select'
                onChange={props.changeCategory}
                value={props.selectedCategory || ''}>
          <option value={''}>Kaikki</option>
          <option value={1}>Kielto- ja rajoitusmerkit</option>
          <option value={2}>Opastusmerkit</option>
          <option value={3}>Työmaan tarvikkeet</option>
          <option value={4}>Varoitusmerkit</option>
          <option value={5}>Lisäkilvet</option>
          <option value={6}>Määräysmerkit</option>
          <option value={7}>Etuajo-oikeus- ja väistämismerkit</option>
          <option value={8}>Ohjemerkit</option>
          <option value={9}>Tiemerkit</option>
          {
            props.ownCategories.map((category, index) => (
              <option key={category.name} value={category.name}>{category.name}</option>
            ))
          }
        </select>
        <button id='new-category-button' onClick={props.newCategory}>
          Uusi kategoria
        </button>
        { ownCategorySelected ?
          <button id='delete-category-button' onClick={props.deleteCategory}>
            Poista kategoria
          </button>
          : null
        }
      </div>
      <div id='traffic-sign-select'>
      { ownCategorySelected ?
        props.ownCategories.find(category => category.name === props.selectedCategory)
        .trafficSigns.map((types, index) => {
          return (
            <div key={index} className='traffic-sign-collection'>
              {
                types.map((type, index) => {
                  return (
                    <img key={type.name} className={'traffic-sign-select-icon traffic-sign-collection-item' + 
                                                    (index > 0 ? ' another' : '')}
                         alt={type.name} src={'images/' + type.icon}
                         onClick={props.newTrafficSign.bind(null, types)} />
                  );
                })
              }
            </div>
          );
        })
        :
        props.trafficSignTypes.map(type => {
          if (props.selectedCategory !== '' && type.get('category') !== parseInt(props.selectedCategory, 10)) {
            return null;
          }
          return (
            <img key={type.get('name')} className='traffic-sign-select-icon'
                alt={type.get('name')} src={'images/' + type.get('icon')}
                onClick={props.newTrafficSign.bind(null, type)}/>
          );
        })
      }
      </div>
    </div>
  );
}

const TrafficSigns = props => {
  if (!props.dimensionsReady) {
    return null;
  }

  let trafficSigns = [];

  for (let i = 0; i < props.trafficSigns.size; i++) {
    let currentHeight = 0;
    let trafficSignsText = [];

    for (let index = props.trafficSigns.get(i).get('signs').size - 1; index >= 0; index--) {
      const sign = props.trafficSigns.get(i).get('signs').get(index);
      const order = index + 1;
      trafficSignsText.unshift(
        <span key={order}>
          <strong>{order + '. '}</strong> {sign.get('name')}
          <br/>
        </span>
      );

      const erected = props.trafficSigns.get(i).get('erected');
      const raised = props.trafficSigns.get(i).get('raised');

      const status = raised != null ? 'raised' : (erected != null ? '' : ' not-erected');
      let className = status;

      if (props.selectedTrafficSign && 
        props.trafficSigns.get(i).get('id') === props.selectedTrafficSign.get('id')) {
        if (props.trafficSigns.get(i).get('signs').size === 1) {
          className += ' selected-traffic-sign';
        }
        else {
          if (index === 0) {
            className += ' selected-traffic-sign-top';
          }
          else if (index === props.trafficSigns.get(i).get('signs').size - 1) {
            className += ' selected-traffic-sign-bottom';
          }
          else {
            className += ' selected-traffic-sign-middle';
          }
        }
      }

      const extraCustomTextClassName = sign.get('customTextColor') != null ? sign.get('customTextColor') : 'black';

      let iconSize = [40, 40];
      let iconAnchor = [20, 40];

      const iconSource = props.getImageIcon(sign.get('name'));

      if (iconSource != null) {
        const dimensions = props.getImageDimensions(iconSource);
        const height = Math.round(dimensions.height * (40 / dimensions.width));
        iconSize = [40, height];
        iconAnchor = [20, Math.round(height + currentHeight)];

        currentHeight += height;
      }

      const icon = new L.divIcon({
        className: 'custom-sign-container',
        iconSize: iconSize,
        iconAnchor: iconAnchor,
        popupAnchor: [0, -currentHeight],
        html: '<img width="40" class="' + className + '" src="images/' + 
              iconSource + '"/>' + (sign.get('customText') ?
              '<span class="custom-sign-text ' + sign.get('customText') + ' ' + status + ' ' + extraCustomTextClassName
              + '">' + sign.get('text') + '</span>' : '') + (index === props.trafficSigns.get(i).get('signs').size - 1 ?
              '<div class="' + (props.trafficSigns.get(i).get('both_side') ? 'traffic-sign-position-both-side' : 'traffic-sign-position')
              + '" />' : '')
      });

      const erectedDate = new Date(erected);
      const erectedText = erectedDate.getDate() + '.' + (erectedDate.getMonth() + 1) + '.' + erectedDate.getFullYear()
                          + ' ' + paddedNumber(erectedDate.getHours()) + ':' + paddedNumber(erectedDate.getMinutes()) +
                          ':' + paddedNumber(erectedDate.getSeconds());
      const raisedDate = new Date(raised);
      const raisedText = raisedDate.getDate() + '.' + (raisedDate.getMonth() + 1) + '.' + raisedDate.getFullYear()
                          + ' ' + paddedNumber(raisedDate.getHours()) + ':' + paddedNumber(raisedDate.getMinutes()) +
                          ':' + paddedNumber(raisedDate.getSeconds());

      trafficSigns.push(
        <Marker key={props.trafficSigns.get(i).get('id') + ' ' + index}
                position={[props.trafficSigns.get(i).get('latitude'), props.trafficSigns.get(i).get('longitude')]}
                icon={icon}
                draggable={erected == null}
                onClick={props.selectTrafficSign.bind(null, props.trafficSigns.get(i))}
                onDragEnd={props.setTrafficSignAfterDrag.bind(this, props.trafficSigns.get(i))}
                ref={ref => props.goToTrafficSign === props.trafficSigns.get(i).get('id') && setTimeout(() =>
                      props.showPopup(ref, [props.trafficSigns.get(i).get('latitude'), props.trafficSigns.get(i).get('longitude')]))}>
          <Popup autoPan={false}>
            <span>
              {trafficSignsText}
              <br/>
              { props.trafficSigns.get(i).get('road_number') ?
                <div>
                  <strong>Tie:</strong> {props.trafficSigns.get(i).get('road_number')}
                  <strong> Tieosa:</strong> {props.trafficSigns.get(i).get('road_part')}
                  <strong> Paalu:</strong> {props.trafficSigns.get(i).get('road_distance')}
                </div>
                : null
              }
              <strong> Suunta:</strong> {props.trafficSigns.get(i).get('direction') || '-'}
              <strong> Tien molemmin puolin:</strong> {props.trafficSigns.get(i).get('both_side') ? 'Kyllä' : 'Ei'}
              <br/>
              { raised == null ? (
                erected == null ?
                  <div>
                    <button className='popup-button' onClick={props.erectTrafficSign.bind(null, props.trafficSigns.get(i))}>
                      Pystytä
                    </button>
                    <button className='popup-button' onClick={props.moveTrafficSign.bind(null, props.trafficSigns.get(i))}>
                      Siirrä
                    </button>
                  </div>
                  :
                  <div>
                    <strong>Pystytetty: </strong>{erectedText}
                    <br/>
                    { props.trafficSigns.get(i).get('image') !== 'null' ?
                      <img className='traffic-sign-image' alt='' src={props.trafficSigns.get(i).get('image')} />
                      :
                      null
                    }
                    <br/>
                    <label className='file-upload'>
                      <i className='fa fa-camera'></i>
                      <input type='file' accept={'image/*'} onChange={props.setImageToTrafficSign.bind(this, props.trafficSigns.get(i))} />
                    </label>
                    <button className='popup-button' onClick={props.raiseTrafficSign.bind(null, props.trafficSigns.get(i))}>
                      Poista
                    </button>
                    <button className='popup-button' onClick={props.copyTrafficSign.bind(null, props.trafficSigns.get(i).get('signs'))}>
                      Kopioi
                    </button>
                  </div>
                )
                : 
                <div>
                  <strong>Pystytetty: </strong>{erectedText}
                  <br/>
                  <strong>Poistettu: </strong>{raisedText}
                  <br/>
                  <br/>
                  <button className='popup-button' onClick={props.copyTrafficSign.bind(null, props.trafficSigns.get(i).get('signs'))}>
                    Kopioi
                  </button>
                  <button className='popup-button' onClick={props.erectAgainTrafficSign.bind(null, props.trafficSigns.get(i))}>
                    Pystytä uudelleen
                  </button>
                </div>
              }
              <br/>
              { erected == null && props.trafficSigns.get(i).get('signs').size > 1 ?
                <button className='popup-button' onClick={props.removeLatestSignType.bind(null, props.trafficSigns.get(i))}>
                  Poista viimeisin merkki
                </button>
                : null
              }
              <button className='delete-button' onClick={props.removeTrafficSign.bind(null, props.trafficSigns.get(i).get('id'))}/>
            </span>
          </Popup>
        </Marker>
      );
    }
  }

  return trafficSigns;
}

const NewCategory = props => {
  if (!props.show) return null;

  const ownCategorySelected = props.selectedCategory !== '' && isNaN(props.selectedCategory);

  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='modal-area-new-category'>
        <div className='close' onClick={props.toggle}/>
        <h4>Uusi kategoria</h4>
        <label htmlFor='name'>Nimi</label>
        <input id='name' type='text' value={props.newCategoryName || ''}
               onChange={props.changeNewCategoryName.bind(this)}/>
        <label id='label-category-select' htmlFor='input-category-select'>Näytä:</label>
        <select id='input-category-select'
                onChange={props.changeCategory}
                value={props.selectedCategory || ''}>
          <option value=''>Kaikki</option>
          <option value={1}>Kielto- ja rajoitusmerkit</option>
          <option value={2}>Opastusmerkit</option>
          <option value={3}>Työmaan tarvikkeet</option>
          <option value={4}>Varoitusmerkit</option>
          <option value={5}>Lisäkilvet</option>
          <option value={6}>Määräysmerkit</option>
          {
            props.ownCategories.map((category, index) => (
              <option key={category.name} value={category.name}>{category.name}</option>
            ))
          }
        </select>
        <button id='toggle-combine-traffic-sign-button'
                className={props.makeCombineTrafficSign ? 'on' : ''}
                onClick={props.toggleMakeCombineTrafficSign}>
          Luo yhdistelmä
        </button>
        <div id='traffic-sign-select-modal'>
        { ownCategorySelected ?
            props.ownCategories.find(category => category.name === props.selectedCategory)
            .trafficSigns.map((types, index) => {
              return (
                <div key={index} className='traffic-sign-collection'>
                  {
                    types.map((type, index) => {
                      return (
                        <img key={type.name} className={'traffic-sign-select-icon traffic-sign-collection-item' + 
                                                        (index > 0 ? ' another' : '')}
                             alt={type.name} src={'images/' + type.icon}
                             onClick={!props.makeCombineTrafficSign ?
                                      props.addTrafficSignToCategory.bind(null, types)
                                      : props.combineTrafficSignInCategory.bind(null, types)} />
                      );
                    })
                  }
                </div>
              );
            })
          :
          props.trafficSignTypes.map(type => {
            if (props.selectedCategory !== '' && type.get('category') !== parseInt(props.selectedCategory, 10)) {
              return null;
            }
            return (
              <img key={type.get('name')} className='traffic-sign-select-icon'
                   alt={type.get('name')} src={'images/' + type.get('icon')}
                   onClick={ !props.makeCombineTrafficSign ?
                             props.addTrafficSignToCategory.bind(null, type)
                             : props.combineTrafficSignInCategory.bind(null, type)}/>
            );
          })
        }
        </div>
        Valitut merkit:
        <div ref={element => newCategoryCurrent = element} id='current-new-category'>
          {
            props.newCategoryTrafficSigns.map((types, index) => {
              return (
                <div key={index} className='traffic-sign-collection'>
                  {
                    types.map((type, index) => {
                      return (
                        <img key={type.name} className={'traffic-sign-select-icon traffic-sign-collection-item' + 
                                                        (index > 0 ? ' another' : '')}
                             alt={type.name} src={'images/' + type.icon}
                             onClick={props.removeTrafficSignFromCategory.bind(null, type)} />
                      );
                    })
                  }
                </div>
              );
            })
          }
        </div>
        <button id='accept-button' onClick={props.createNewCategory}>
          Luo kategoria
        </button>
      </div>
    </div>
  );
}

const ChooseDirection = props => {
  if (!props.show) return null;

  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='modal-area-direction-selection'>
        <div className='close' onClick={props.toggle}/>
        { props.loading ?
          <div className='loader center'/>
          :
          <div>
            <h4>Valitse vaikuttava suunta</h4>
            { !props.freeDirectionRotation && props.foundRoad ?
              <div>
                <span>
                  Löydetty tie: {props.foundRoad}
                { props.lockedRoad == null ?
                  <span className='link' onClick={props.lockRoad}> Lukitse tie</span>
                :
                  <span className='link' onClick={props.clearLockedRoad}> Poista lukitus ({props.lockedRoad})</span>
                }
                </span>
              </div>
              :
              null
            }
            { props.freeDirectionRotation && props.lockedRoad != null ?
              <span className='link' onClick={props.clearLockedRoad}>Poista lukitus ({props.lockedRoad})</span>
            :
              null
            }
            <br />
            { props.freeDirectionRotation ?
              <img id='background-image' alt='' src='images/compass-rose.png' />
              :
              null
            }
            <div id='direction-select' style={{transform:'rotate(' + props.rotation + 'deg)'}}>
              { props.freeDirectionRotation ?
                <div id='rotate-object' onTouchStart={props.startRotateDirection}
                    onMouseDown={props.startRotateDirection} />
              :
                null
              }
              <div className='direction' onClick={props.selectDirection.bind(null, 2)}>
                <i className='fa fa-chevron-down' /> { props.freeDirectionRotation ? null : 2 }
                { props.selectedType ?
                  <div className='image-container'>
                    <img className='traffic-sign-select-icon flip'
                         alt='2' src={'images/' + (props.selectedType[0] ? props.selectedType[0].icon : props.selectedType.get('icon'))}/>
                  </div>
                  : null
                } 
              </div>
              <div className='direction' onClick={props.selectDirection.bind(null, 1)}>
                { props.selectedType ?
                  <div className='image-container'>
                    <img className='traffic-sign-select-icon'
                        alt='1' src={'images/' + (props.selectedType[0] ? props.selectedType[0].icon : props.selectedType.get('icon'))}/>
                  </div>
                  : null
                }
                <i className='fa fa-chevron-up' /> { props.freeDirectionRotation ? null : 1 }
              </div>
            </div>
            { props.freeDirectionRotation ?
              <button onClick={props.unSetFreeRotation}>
                Hae tie automaattisesti
              </button>
              :
              <button onClick={props.setFreeRotation}>
                Päätä itse suunta
              </button>
            }
          </div>
        }
      </div>
    </div>
  );
}


class TrafficSignPage extends React.Component {
  constructor(props) {
    super(props);

    this.socket = null;
    this.dimensions = {};

    this.state = {
      loadingTypes: false,
      yourLaditude: null,
      yourLongitude: null,
      markerLaditude: null,
      markerLongitude: null,
      lockedLocation: true,
      selectedCategory: '',
      ownCategories: [],
      selectedDirection: null,
      chooseDirectionRotation: 0,
      freeDirectionRotation: false,
      markBothSide: !(localStorage.markBothSide === 'false'),
      newCategoryTrafficSigns: [],
      lockedRoad: null,
      goToTrafficSign: null,
      mapTilesUrl: '',
      mapTilesAttribution: '',
      maxZoom: 16
    };

    this.getTrafficSigns = this.getTrafficSigns.bind(this);
    this.setMarker = this.setMarker.bind(this);
    this.clearMarker = this.clearMarker.bind(this);
    this.setMarkerAfterDrag = this.setMarkerAfterDrag.bind(this);
    this.setTrafficSignAfterDrag = this.setTrafficSignAfterDrag.bind(this);
    this.changeTrafficSignAfterDrag = this.changeTrafficSignAfterDrag.bind(this);
    this.newTrafficSign = this.newTrafficSign.bind(this);
    this.updateTrafficSign = this.updateTrafficSign.bind(this);
    this.toggleLockedLocation = this.toggleLockedLocation.bind(this);
    this.selectTrafficSign = this.selectTrafficSign.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.getImageIcon = this.getImageIcon.bind(this);
    this.getImageDimensions = this.getImageDimensions.bind(this);
    this.erectTrafficSign = this.erectTrafficSign.bind(this);
    this.raiseTrafficSign = this.raiseTrafficSign.bind(this);
    this.confirmErectAllTrafficSigns = this.confirmErectAllTrafficSigns.bind(this);
    this.erectAllTrafficSigns = this.erectAllTrafficSigns.bind(this);
    this.confirmRaiseAllTrafficSigns = this.confirmRaiseAllTrafficSigns.bind(this);
    this.raiseAllTrafficSigns = this.raiseAllTrafficSigns.bind(this);
    this.confirmRemoveTrafficSign = this.confirmRemoveTrafficSign.bind(this);
    this.removeTrafficSign = this.removeTrafficSign.bind(this);
    this.confirmRemoveLatestSignType = this.confirmRemoveLatestSignType.bind(this);
    this.removeLatestSignType = this.removeLatestSignType.bind(this);
    this.toggleNewCategory = this.toggleNewCategory.bind(this);
    this.createNewCategory = this.createNewCategory.bind(this);
    this.confirmDeleteCategory = this.confirmDeleteCategory.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.addTrafficSignToCategory = this.addTrafficSignToCategory.bind(this);
    this.combineTrafficSignInCategory = this.combineTrafficSignInCategory.bind(this);
    this.removeTrafficSignFromCategory = this.removeTrafficSignFromCategory.bind(this);
    this.changeNewCategoryName = this.changeNewCategoryName.bind(this);
    this.copyTrafficSign = this.copyTrafficSign.bind(this);
    this.moveTrafficSign = this.moveTrafficSign.bind(this);
    this.erectAgainTrafficSign = this.erectAgainTrafficSign.bind(this);
    this.toggleChooseDirection = this.toggleChooseDirection.bind(this);
    this.selectDirection = this.selectDirection.bind(this);
    this.setFreeRotation = this.setFreeRotation.bind(this);
    this.unSetFreeRotation = this.unSetFreeRotation.bind(this);
    this.startRotateDirection = this.startRotateDirection.bind(this);
    this.toggleMarkBothSide = this.toggleMarkBothSide.bind(this);
    this.toggleMakeCombineTrafficSign = this.toggleMakeCombineTrafficSign.bind(this);
    this.setImageToTrafficSign = this.setImageToTrafficSign.bind(this);
    this.lockRoad = this.lockRoad.bind(this);
    this.clearLockedRoad = this.clearLockedRoad.bind(this);
    this.showPopup = this.showPopup.bind(this);
  }

  componentWillMount() {
    if (localStorage['login'] == null) {
      return;
    }
    
    this.setAllImageDimensions();

    if (this.props.selectedConstructionSite != null) {
      this.getTrafficSigns(this.props.selectedConstructionSite);
    }

    if (this.socket == null) {
      this.socket = Socket('/data/trafficsign');
      this.socket.onmessage = function(e) {
        const data = JSON.parse(e.data);
        console.log(data);
        if (data['operation'] === 'create') {
          this.props.addTrafficSign(data.model);
        }
        else if (data['operation'] === 'update') {
          this.props.changeTrafficSign(data.model);
        }
        else if (data['operation'] === 'delete') {
          this.props.removeTrafficSign(data.model);
        }
      }.bind(this)
    }

    if (!navigator.geolocation) {
      this.props.showMessage('Varoitus', 
        'Paikan haku ei ole tuettu tällä selaimella eli et voi saada sinun sijaintia kartalle', 'Warning');
      return;
    }

    this.watchID = navigator.geolocation.watchPosition(position => {
      this.setState({
        yourLaditude: position.coords.latitude,
        yourLongitude: position.coords.longitude
      });
    }, error => {
      console.log(error.code);
    }, {enableHighAccuracy: true});

    if (localStorage['savedCategories'] != null) {
      this.setState({
        ownCategories: JSON.parse(localStorage['savedCategories'])
      });
    }
  }

  componentWillUnmount() {
    if (this.socket != null) this.socket.close();
    if (this.watchID != null) {
      navigator.geolocation.clearWatch(this.watchID);
    }
  }

  async componentWillUpdate(nextProps, nextState) {
    const mapCenter = this.map.leafletElement.getCenter();
    const converted = toETRSTM35FIN(mapCenter.lat, mapCenter.lng)
    const mapTiles = MapTiles(converted.x, converted.y);

    if (this.state.mapTilesUrl !== mapTiles.url) {
      this.setState({
        mapTilesUrl: mapTiles.url,
        mapTilesAttribution: mapTiles.attribution,
        maxZoom: mapTiles.maxZoom
      });
    }

    if ((this.state.yourLaditude !== nextState.yourLaditude ||
        this.state.yourLongitude !== nextState.yourLongitude ||
        this.state.lockedLocation !== nextState.lockedLocation) &&
        nextState.lockedLocation && nextState.yourLaditude != null &&
        this.map != null) {
      this.map.leafletElement.setView([nextState.yourLaditude, nextState.yourLongitude], 17);
    }
    if (this.props.selectedConstructionSite !== nextProps.selectedConstructionSite) {
      this.getTrafficSigns(nextProps.selectedConstructionSite);
    }
  }

  async getTrafficSigns(site, sentSavedTrafficSigns = false) {
    if (site == null) {
      this.props.addTrafficSigns([]);
      return;
    }

    const id = site.get('id')
    
    this.setState({
      loadingTrafficSigns: true
    });

    let trafficSigns = [];

    try {
      trafficSigns = await fetch('/trafficsigns?siteId=' + id);
      if (!sentSavedTrafficSigns &&
          typeof(Storage) !== 'undefined' &&
          localStorage['savedTrafficSigns'] != null &&
          JSON.parse(localStorage['savedTrafficSigns']).length !== 0) {
        this.sendSavedTrafficSigns();
        return;
      }
      for (let index in trafficSigns) {
        let trafficSign = trafficSigns[index];
        if (trafficSign.image !== 'null') {
          const type = 'image/' + trafficSign.image.split('.')[1];
          try {
            const response = await fetch(trafficSign.image, 'GET', null, type);
            const fileData = await this.loadImage(response);
            trafficSign.image = fileData;
          } catch(error) {
            console.log(error);
          }
        }
      }
    } catch(error) {
      console.log(error);
    }

    if (typeof(Storage) !== 'undefined' &&
        localStorage['savedTrafficSigns'] != null) {
      const savedTrafficSigns = JSON.parse(localStorage['savedTrafficSigns']);
      trafficSigns = trafficSigns.concat(savedTrafficSigns.filter(trafficSign => trafficSign.constructionSiteId === id));
    }

    this.props.addTrafficSigns(trafficSigns);

    this.setState({
      loadingTrafficSigns: false
    });
  }

  sendSavedTrafficSigns() {
    let trafficSigns = JSON.parse(localStorage['savedTrafficSigns']);
    let newTrafficSigns = trafficSigns.slice();
    let index = 0;

    trafficSigns.forEach(async trafficSign => {
      delete trafficSign.id;

      const converted = toETRSTM35FIN(trafficSign.latitude, trafficSign.longitude);
      const roadData = await getRoadData(converted.y, converted.x);
      trafficSign.road_number = roadData.road || null;
      trafficSign.road_part = roadData.part || null;
      trafficSign.road_distance = roadData.distance || null;
      await fetch('/trafficsigns/', 'POST', trafficSign);
      newTrafficSigns.splice(newTrafficSigns.findIndex(sign => sign['id'] === trafficSign['id']), 1);

      index++;

      if (index === trafficSigns.length) {
        localStorage['savedTrafficSigns'] = JSON.stringify(newTrafficSigns);
        this.getTrafficSigns(this.props.selectedConstructionSite, true);
      }
    });
  }
  
  setMarker(event) {
    this.setState({
      markerLaditude: event.latlng.lat,
      markerLongitude: event.latlng.lng,
      selectedTrafficSign: null
    }, () => { 
      if (this.state.copyingType != null) {
        this.newTrafficSign(this.state.copyingType);
        this.setState({ copyingType: null });
      }
      else if (this.state.draggingTrafficSign != null) {
        this.toggleChooseDirection();
      }
    });
  }

  clearMarker() {
    this.setState({
      markerLaditude: null,
      markerLongitude: null,
      selectedTrafficSign: null
    });
    this.map.leafletElement.closePopup();
  }

  setMarkerAfterDrag(event) {
    this.setState({
      markerLaditude: event.target._latlng.lat,
      markerLongitude: event.target._latlng.lng
    });
  }

  async setTrafficSignAfterDrag(trafficSign, event) {
    const type = this.props.trafficSignTypes.find(type => type.get('name') ===
                                                    trafficSign.get('signs').get(0).get('name'));

    this.setState({
      oldMarkerLatitude: this.state.markerLaditude,
      oldMarkerLongitude: this.state.markerLongitude,
      markerLaditude: event.target._latlng.lat,
      markerLongitude: event.target._latlng.lng,
      draggingTrafficSign: trafficSign,
      selectedType: type
    }, () => {
      this.toggleChooseDirection();
    });
  }

  changeTrafficSignAfterDrag() {
    const movedTrafficSign = {
      id: this.state.draggingTrafficSign.get('id'),
      time: this.state.draggingTrafficSign.get('time'),
      constructionSiteId: this.state.draggingTrafficSign.get('constructionSiteId'),
      signs: this.state.draggingTrafficSign.get('signs'),
      latitude: this.state.markerLaditude,
      longitude: this.state.markerLongitude,
      road_number: this.state.foundRoad ? this.state.foundRoad : null,
      road_part: this.state.foundRoadPart ? this.state.foundRoadPart : null,
      road_distance: this.state.foundRoadPart ? this.state.foundRoadPart : null,
      direction: this.state.selectedDirection,
      angle: this.state.chooseDirectionRotation
    };
    this.updateTrafficSign(movedTrafficSign);
  }

  async newTrafficSign(type) {
    if (this.props.selectedConstructionSite == null) {
      this.props.showNotice('Valitse ensin haluttu kohde', 'Warning');
      return;
    }

    let latitude = this.state.markerLaditude;
    let longitude = this.state.markerLongitude;

    if (this.state.selectedTrafficSign == null && latitude == null) {
      if (this.state.yourLaditude == null) {
        this.props.showNotice('Koska sijaintiasi ei ole saatavana. Valitse kartalta ensin paikka, mihin merkki laitetaan.', 'Warning');
        return;
      }
      latitude = this.state.yourLaditude;
      longitude = this.state.yourLongitude;
    }

    let sameLocatedTrafficSign = null;

    if (this.state.selectedTrafficSign != null) {
      sameLocatedTrafficSign = this.state.selectedTrafficSign;
    }
    else {
      sameLocatedTrafficSign = this.props.trafficSigns.find(
        sign => sign.get('latitude') === latitude && sign.get('longitude') === longitude);
    }

    if (sameLocatedTrafficSign != null && sameLocatedTrafficSign.get('erected') != null) {
      return;
    }

    // We have already traffic sign in this spot, so we update it
    if (sameLocatedTrafficSign != null) {
      let existingType = false;

      if (type.get) {
        existingType = sameLocatedTrafficSign.get('signs').find(t => t.get('name') === type.get('name'));
      }
      else {
        for (let i in type) {
          if (sameLocatedTrafficSign.get('signs').find(t => t.get('name') === type[i].name)) {
            existingType = true;
            break;
          }
        }
      }

      if (existingType) {
        this.props.showNotice('Sama merkki on jo tässä', 'Warning');
        return;
      }

      if (type.get) {
        if (type.get('customText')) {
          const text = prompt('Teksti kylttiin (' + type.get('name') + '):', '');
          if (text == null) return;
          type = type.set('text', text);
        }
      }
      else {
        for (let i in type) {
          if (type[i].customText) {
            const text = prompt('Teksti kylttiin (' + type[i].name + '):', '');
            if (text == null) return;
            type[i].text = text;
          }
        }
      }

      let types = sameLocatedTrafficSign.get('signs');

      if (type.get) {
        types = types.push(type);
      }
      else {
        types = types.concat(type);
      }

      const trafficSign = {
        id: sameLocatedTrafficSign.get('id'),
        time: sameLocatedTrafficSign.get('time'),
        constructionSiteId: sameLocatedTrafficSign.get('constructionSiteId'),
        signs: types,
        latitude: sameLocatedTrafficSign.get('latitude'),
        longitude: sameLocatedTrafficSign.get('longitude')
      };

      if (this.state.selectedTrafficSign != null) {
        this.setState({
          selectedTrafficSign: fromJS(trafficSign)
        });
      }

      this.updateTrafficSign(trafficSign);
      return;
    }

    if (this.state.selectedDirection == null) {
      this.setState({
        selectedType: type
      }, () => {
        this.toggleChooseDirection();
      });
      return;
    }

    if (type.get) {
      if (type.get('customText')) {
        const text = prompt('Teksti kylttiin (' + type.get('name') + '):', '');
        if (text == null) return;
        type = type.set('text', text);
      }
    }
    else {
      for (let i in type) {
        if (type[i].customText) {
          const text = prompt('Teksti kylttiin (' + type[i].name + '):', '');
          if (text == null) return;
          type[i].text = text;
        }
      }
    }

    const time = new Date();

    let types = [];

    if (type.get) {
      types.push({
        name: type.get('name'),
        category: type.get('category'),
        icon: type.get('icon'),
        customText: type.get('customText'),
        customTextColor: type.get('customTextColor'),
        text: type.get('text'),
      })
    }
    else {
      types = type;
    }

    const converted = toETRSTM35FIN(latitude, longitude);
    const roadData = await getRoadData(converted.y, converted.x);

    let angle = this.state.chooseDirectionRotation;

    if (angle != null && this.state.direction === 1) {
      angle += 180;
    }

    let trafficSign = {
      time: time.toISOString(),
      constructionSiteId: this.props.selectedConstructionSite.get('id'),
      signs: types,
      latitude: latitude,
      longitude: longitude,
      road_number: roadData.road || null,
      road_part: roadData.part || null,
      road_distance: roadData.distance || null,
      direction: this.state.selectedDirection,
      angle: angle,
      both_side: this.state.markBothSide
    };

    try {
      trafficSign = await fetch('/trafficsigns', 'POST', trafficSign);
    } catch(error) {
      if (localStorage['savedTrafficSigns'] == null) {
        localStorage['savedTrafficSigns'] = JSON.stringify([]);
      }
      let trafficSigns = JSON.parse(localStorage['savedTrafficSigns']);
      trafficSign.id = Date.now();
      trafficSigns.push(trafficSign);
      localStorage['savedTrafficSigns'] = JSON.stringify(trafficSigns);
    }

    this.props.addTrafficSign(trafficSign);

    this.setState({ goToTrafficSign: trafficSign.id }, () => {
      this.setState({ goToTrafficSign: null });
    });
  }

  async updateTrafficSign(trafficSign) {
    try {
      trafficSign = await fetch('/trafficsigns/' + trafficSign.id, 'PATCH', trafficSign);
    } catch(error) {
      if (localStorage['savedTrafficSigns'] != null) {
        let trafficSigns = JSON.parse(localStorage['savedTrafficSigns']);

        let editingTrafficSignIndex = trafficSigns.findIndex(sign => sign.id === trafficSign.id);

        if (editingTrafficSignIndex !== -1) {
          trafficSigns.splice(editingTrafficSignIndex, 1);
          trafficSigns.push(trafficSign);
          localStorage['savedTrafficSigns'] = JSON.stringify(trafficSigns);
          this.props.changeTrafficSign(trafficSign);
          return;
        }
      }
      if (localStorage['updatedTrafficSigns'] == null) {
        localStorage['updatedTrafficSigns'] = JSON.stringify([]);
      }

      let trafficSigns = JSON.parse(localStorage['updatedTrafficSigns']);
      trafficSigns.push(trafficSign);
      localStorage['updatedTrafficSigns'] = JSON.stringify(trafficSigns);
    }

    this.setState({
      selectedTrafficSign: fromJS(trafficSign)
    });

    if (trafficSign.image !== 'null') {
      const type = 'image/' + trafficSign.image.split('.')[1];
      const response = await fetch(trafficSign.image, 'GET', null, type);
      const fileData = await this.loadImage(response);
      trafficSign.image = fileData;
    }

    this.props.changeTrafficSign(trafficSign);

    this.setState({ goToTrafficSign: trafficSign.id }, () => {
      this.setState({ goToTrafficSign: null });
    });
  }

  toggleLockedLocation() {
    this.setState({
      lockedLocation: !this.state.lockedLocation
    });
  }

  selectTrafficSign(trafficSign) {
    this.setState({
      selectedTrafficSign: trafficSign,
      markerLaditude: null,
      markerLongitude: null
    });
  }

  changeCategory(event) {
    this.setState({
      selectedCategory: event.target.value
    });
  }

  getImageIcon(name) {
    const foundType = this.props.trafficSignTypes.find(type => type.get('name') === name);

    if (foundType != null) {
      return this.props.trafficSignTypes.find(type => type.get('name') === name).get('icon');
    }

    return null;
  }

  setImageDimensions(file) {
    return new Promise (function (resolved, rejected) {
      let image = new Image();
      image.onload = function() {
        resolved({width: image.width, height: image.height})
      };
      image.src = file;
    })
  }

  async setAllImageDimensions() {
    for (let index = 0; index < this.props.trafficSignTypes.size; index++) {
      const type = this.props.trafficSignTypes.get(index);
      this.dimensions[type.get('icon')] = await this.setImageDimensions('images/' + type.get('icon'));
      if (index + 1 === this.props.trafficSignTypes.size) {
        this.setState({
          dimensionsReady: true
        });
      }
    }
  }

  getImageDimensions(file) {
    return this.dimensions[file];
  }

  erectTrafficSign(trafficSign) {
    const time = new Date();

    const editedTrafficSign = {
      id: trafficSign.get('id'),
      time: trafficSign.get('time'),
      erected: time.toISOString(),
      constructionSiteId: trafficSign.get('constructionSiteId'),
      signs: trafficSign.get('signs'),
      latitude: trafficSign.get('latitude'),
      longitude: trafficSign.get('longitude'),
      road_number: trafficSign.get('road_number'),
      road_part: trafficSign.get('road_part'),
      road_distance: trafficSign.get('road_distance'),
      direction: trafficSign.get('direction'),
      angle: trafficSign.get('angle')
    };

    this.updateTrafficSign(editedTrafficSign);
  }

  confirmErectAllTrafficSigns() {
    this.props.showConfirm('Pystytetäänkö kaikki pystymättömät liikennemerkit?', this.erectAllTrafficSigns);
  }

  erectAllTrafficSigns() {
    this.props.trafficSigns.forEach(trafficSign => {
      if (trafficSign.get('erected') == null) {
        this.erectTrafficSign(trafficSign);
      }
    });
  }

  raiseTrafficSign(trafficSign) {
    const time = new Date();

    const editedTrafficSign = {
      id: trafficSign.get('id'),
      time: trafficSign.get('time'),
      erected: trafficSign.get('erected'),
      raised: time.toISOString(),
      constructionSiteId: trafficSign.get('constructionSiteId'),
      signs: trafficSign.get('signs'),
      latitude: trafficSign.get('latitude'),
      longitude: trafficSign.get('longitude'),
      road_number: trafficSign.get('road_number'),
      road_part: trafficSign.get('road_part'),
      road_distance: trafficSign.get('road_distance'),
      direction: trafficSign.get('direction'),
      angle: trafficSign.get('angle')
    };

    this.updateTrafficSign(editedTrafficSign);
  }

  confirmRaiseAllTrafficSigns() {
    this.props.showConfirm('Poistetaanko kaikki pystytetyt liikennemerkit?', this.raiseAllTrafficSigns);
  }

  raiseAllTrafficSigns() {
    this.props.trafficSigns.forEach(trafficSign => {
      if (trafficSign.get('erected') != null &&
          trafficSign.get('raised') == null) {
        this.raiseTrafficSign(trafficSign);
      }
    });
  }

  confirmRemoveTrafficSign(id) {
    this.setState({
      removingTrafficSignId: id
    });
    this.props.showConfirm('Poistetaanko liikennemerkki kokonaan järjestelmästä? (Ei jätä mitään jälkeä merkistä)', this.removeTrafficSign);
  }

  async removeTrafficSign() {
    const id = this.state.removingTrafficSignId;

    try {
      await fetch('/trafficsigns/' + id, 'DELETE');
      this.props.removeTrafficSign(id);
    } catch(error) {
      if (localStorage['savedTrafficSigns'] != null) {
        let trafficSigns = JSON.parse(localStorage['savedTrafficSigns']);
        if (trafficSigns.length !== 0) {
          const index = trafficSigns.find(sign => sign.id === id);
          if (index !== -1) {
            trafficSigns.splice(index, 1);
            localStorage['savedTrafficSigns'] = JSON.stringify(trafficSigns);
            this.props.removeTrafficSign(id);
            return;
          }
        }
      }
      this.props.showMessage('Virhe', 'Liikennemerkin poisto epäonnistui', 'Error');
    }
  }

  confirmRemoveLatestSignType(trafficSign) {
    this.setState({
      editingTrafficSign: trafficSign
    });
    this.props.showConfirm('Poistetaanko liikennemerkistä viimeisin merkki?', this.removeLatestSignType);
  }

  async removeLatestSignType() {
    const trafficSign = this.state.editingTrafficSign;
    let newType = trafficSign.get('signs');
    newType = newType.delete(newType.size - 1);

    const editedTrafficSign = {
      id: trafficSign.get('id'),
      time: trafficSign.get('time'),
      erected: trafficSign.get('erected'),
      raised: trafficSign.get('raised'),
      constructionSiteId: trafficSign.get('constructionSiteId'),
      signs: newType,
      latitude: trafficSign.get('latitude'),
      longitude: trafficSign.get('longitude'),
      road_number: trafficSign.get('road_number'),
      road_part: trafficSign.get('road_part'),
      road_distance: trafficSign.get('road_distance'),
      direction: trafficSign.get('direction'),
      angle: trafficSign.get('angle')
    };

    this.updateTrafficSign(editedTrafficSign);
  }

  toggleNewCategory() {
    this.setState({
      showNewCategory: !this.state.showNewCategory,
      newCategoryTrafficSigns: [],
      newCategoryName: ''
    });
  }

  createNewCategory() {
    if (this.state.newCategoryName === '') {
      this.props.showNotice('Kategorialle ei ole annettu nimeä', 'Warning');
      return;
    }

    const exist = this.state.ownCategories.find(category => category.name === this.state.newCategoryName);

    if (exist != null) {
      this.props.showNotice('Tämän niminen kategoria on jo olemassa', 'Warning');
      return;
    }

    if (this.state.newCategoryTrafficSigns.length === 0) {
      this.props.showNotice('Kategorialle ei ole yhtään merkkiä laitettu', 'Warning');
      return;
    }

    if (localStorage['savedCategories'] == null) {
      localStorage['savedCategories'] = JSON.stringify([]);
    }

    let categories = JSON.parse(localStorage['savedCategories']);
    categories.push({
      name: this.state.newCategoryName,
      trafficSigns: this.state.newCategoryTrafficSigns
    });
    localStorage['savedCategories'] = JSON.stringify(categories);
    this.setState({
      ownCategories: categories,
      showNewCategory: false
    });
    this.props.showNotice('Kategoria luotu', 'Ok');
  }

  confirmDeleteCategory() {
    this.props.showConfirm('Poistetaanko kategoria ' + this.state.selectedCategory + '?', this.deleteCategory);
  }

  async deleteCategory() {
    timer(0).then(() => {
      let categories = JSON.parse(localStorage['savedCategories']);
      const index = categories.findIndex(category => category.name === this.state.selectedCategory);
      categories.splice(index, 1);
      localStorage['savedCategories'] = JSON.stringify(categories);
      this.setState({
        ownCategories: categories,
        selectedCategory: ''
      });
      this.props.showNotice('Kategoria poistettu', 'Ok');
    });
  }

  addTrafficSignToCategory(type) {
    let newType;

    if (type.get) {
      newType = [{
        name: type.get('name'),
        category: type.get('category'),
        icon: type.get('icon'),
        customText: type.get('customText')
      }];
    }
    else {
      newType = type;
    }

    let newCategoryTrafficSigns = this.state.newCategoryTrafficSigns;
    newCategoryTrafficSigns.push(newType);

    this.setState({
      newCategoryTrafficSigns: newCategoryTrafficSigns
    });
  }

  combineTrafficSignInCategory(type) {
    const lastIndex = this.state.newCategoryTrafficSigns.length - 1;

    if (lastIndex === -1) {
      this.addTrafficSignToCategory(type);
      return;
    }

    let lastTrafficSign = this.state.newCategoryTrafficSigns[lastIndex];

    let newType;

    if (type.get) {
      if (lastTrafficSign.find(sign => sign.name === type.get('name'))) {
        this.props.showNotice('Kyseinen merkki on jo ryhmässä', 'Warning');
        return;
      }

      newType = {
        name: type.get('name'),
        category: type.get('category'),
        icon: type.get('icon'),
        customText: type.get('customText')
      }
    }
    else {
      for (let i in type) {
        if (lastTrafficSign.find(sign => sign.name === type[i].name)) {
          this.props.showNotice('Kyseinen merkki on jo ryhmässä', 'Warning');
          return;
        }
      }
      newType = type;
    }

    lastTrafficSign = lastTrafficSign.concat(newType);
    let category = this.state.newCategoryTrafficSigns;
    category[lastIndex] = lastTrafficSign;

    this.setState({
      newCategoryTrafficSigns: category
    }, () => {
      const scrollHeight = newCategoryCurrent.scrollHeight;
      const height = newCategoryCurrent.clientHeight;
      const maxScrollTop = scrollHeight - height;
      newCategoryCurrent.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    });
  }

  removeTrafficSignFromCategory(type) {
    let trafficSigns = this.state.newCategoryTrafficSigns;
    const index = trafficSigns.findIndex(trafficSign => trafficSign.name === type.name);
    trafficSigns.splice(index, 1);

    this.setState({
      newCategoryTrafficSigns: trafficSigns
    });
  }

  changeNewCategoryName(event) {
    this.setState({
      newCategoryName: event.target.value
    });
  }

  copyTrafficSign(type) {
    let newType = [];

    for (let i = 0; i < type.size; i++) {
      newType.push({
        name: type.get(i).get('name'),
        category: type.get(i).get('category'),
        icon: this.getImageIcon(type.get(i).get('name')),
        customText: type.get(i).get('customText')
      });
    }

    this.setState({ copyingType: newType });
    this.props.showNotice('Paina kartalta kohta mihin haluat kopion menevän', 'Ok');
  }

  moveTrafficSign(trafficSign) {
    const type = this.props.trafficSignTypes.find(type => type.get('name') ===
                                                    trafficSign.get('signs').get(0).get('name'));
    this.setState({
      draggingTrafficSign: trafficSign,
      selectedType: type
     });
    this.props.showNotice('Paina kartalta kohta mihin haluat merkin', 'Ok');
    this.map.leafletElement.closePopup();
  }

  async erectAgainTrafficSign(trafficSign) {
    const time = new Date();

    let newTrafficSign = {
      time: time.toISOString(),
      constructionSiteId: trafficSign.get('constructionSiteId'),
      signs: trafficSign.get('signs'),
      latitude: trafficSign.get('latitude'),
      longitude: trafficSign.get('longitude'),
      erected: time.toISOString(),
      road_number: trafficSign.get('road_number'),
      road_part: trafficSign.get('road_part'),
      road_distance: trafficSign.get('road_distance'),
      direction: trafficSign.get('direction'),
      angle: trafficSign.get('angle')
    }

    try {
      newTrafficSign = await fetch('/trafficsigns', 'POST', newTrafficSign);
    } catch(error) {
      if (localStorage['savedTrafficSigns'] == null) {
        localStorage['savedTrafficSigns'] = JSON.stringify([]);
      }
      let trafficSigns = JSON.parse(localStorage['savedTrafficSigns']);
      newTrafficSign.id = Date.now();
      trafficSigns.push(newTrafficSign);
      localStorage['savedTrafficSigns'] = JSON.stringify(trafficSigns);
    }

    this.props.addTrafficSign(newTrafficSign);

    this.map.leafletElement.closePopup();
  }

  async setRoadRotation() {
    this.setState({ loadingRoad: true });
    const converted = toETRSTM35FIN(this.state.markerLaditude || this.state.yourLaditude,
                                    this.state.markerLongitude || this.state.yourLongitude);

    let road = null;

    if (this.state.lockedRoad != null) {
      road = this.state.lockedRoad;
    }

    let degree = 0;

    const roadData = await getRoadData(converted.y, converted.x, 10, road);

    if (roadData != null) {
      let x = roadData.x;
      let y = roadData.y;

      const distanceX = x - converted.x;
      const distanceY = y - converted.y;
      const allowDistance = 20;

      if (Math.sqrt(distanceX * distanceX + distanceY * distanceY) <= allowDistance) {
        const roadNumber = roadData.road;
        const roadPart = roadData.part;
        const roadDistance = roadData.distance;

        const distanceBetween = 15;
        let anotherPoint;

        let swap = false;

        if (roadDistance < distanceBetween) {
          anotherPoint = roadDistance + distanceBetween;
          swap = true;
        }
        else {
          anotherPoint = roadDistance - distanceBetween;
        }

        const secondRoadData = await getRoadCoordinates(roadNumber, roadPart, anotherPoint)

        let x2 = secondRoadData.x;
        let y2 = secondRoadData.y;

        if (swap) {
          [x, x2] = [x2, x];
          [y, y2] = [y2, y];
        }

        degree = -(Math.atan2(y - y2, x - x2) * (180 / Math.PI)) - 270;
      }
    }

    const foundRoad = roadData ? roadData.road : null;

    if (foundRoad == null) {
      this.setFreeRotation();
      this.props.showNotice('Tie tietoja ei löytynyt', 'Warning');
    }

    const foundRoadPart = roadData ? roadData.part : null;
    const foundRoadDistance = roadData ? roadData.distance : null;

    this.setState({
      chooseDirectionRotation: degree,
      foundRoad: foundRoad,
      foundRoadPart: foundRoadPart,
      foundRoadDistance: foundRoadDistance,
      loadingRoad: false
    });
  }

  async toggleChooseDirection() {
    if (this.state.preventDirectionToggle) {
      this.setState({ preventDirectionToggle: false });
      return;
    }
    if (!this.state.chooseDirection) {
      await this.setRoadRotation();
    }
    this.setState({
      chooseDirection: !this.state.chooseDirection,
      freeDirectionRotation: false
    });
    if (this.state.draggingTrafficSign && this.state.chooseDirection) {
      this.setState({
        draggingTrafficSign: null,
        markerLaditude: this.state.oldMarkerLatitude,
        markerLongitude: this.state.oldMarkerLongitude
      });
    }
  }

  selectDirection(direction) {
    this.setState({
      selectedDirection: direction,
      chooseDirection: false
    }, async () => {
      if (this.state.draggingTrafficSign) {
        this.changeTrafficSignAfterDrag();
        this.setState({
          draggingTrafficSign: null,
          markerLaditude: this.state.oldMarkerLatitude,
          markerLongitude: this.state.oldMarkerLongitude
        });
      }
      else {
        await this.newTrafficSign(this.state.selectedType)
      }

      this.setState({ selectedDirection: null });
    });
  }

  setFreeRotation() {
    this.setState({
      previousDirectionRotation: this.state.chooseDirectionRotation,
      chooseDirectionRotation: 0,
      freeDirectionRotation: true
    });
  }

  async unSetFreeRotation() {
    await this.setRoadRotation();
    this.setState({
      chooseDirectionRotation: this.state.previousDirectionRotation,
      freeDirectionRotation: false
    });
  }

  startRotateDirection(e) {
    const touchMove = function(e) {
                        const screenWidth = window.innerWidth
                                            || document.documentElement.clientWidth
                                            || document.body.clientWidth;
                        const screenHeight = window.innerHeight
                                            || document.documentElement.clientHeight
                                            || document.body.clientHeight;
                        const touch = e.targetTouches[0];
                        const x = touch.screenX;
                        const y = touch.screenY;
                        const degree = (Math.atan2(y - screenHeight / 2, x - screenWidth / 2) * (180 / Math.PI)) - 270;
                        this.setState({
                          chooseDirectionRotation: degree
                        });
                      }.bind(this);

    const touchEnd = function(e) {
                      this.trafficSignPage.removeEventListener('touchmove', touchMove);
                      this.trafficSignPage.removeEventListener('touchend', touchEnd);
                      this.setState({
                        draggingDirectionRotation: false,
                        preventDirectionToggle: true
                      });
                    }.bind(this);
    this.trafficSignPage.addEventListener('touchmove', touchMove);
    this.trafficSignPage.addEventListener('touchend', touchEnd);

    const mouseMove = function(e) {
          const screenWidth = window.innerWidth
                              || document.documentElement.clientWidth
                              || document.body.clientWidth;
          const screenHeight = window.innerHeight
                              || document.documentElement.clientHeight
                              || document.body.clientHeight;
          const x = e.clientX;
          const y = e.clientY;
          const degree = (Math.atan2(y - screenHeight / 2, x - screenWidth / 2) * (180 / Math.PI)) - 270;
          this.setState({
            chooseDirectionRotation: degree
          });
        }.bind(this);

    const mouseUp = function(e) {
                      this.trafficSignPage.removeEventListener('mousemove', mouseMove);
                      this.trafficSignPage.removeEventListener('mouseup', mouseUp);
                      this.setState({
                        draggingDirectionRotation: false,
                        preventDirectionToggle: true
                      });
                    }.bind(this);
    this.trafficSignPage.addEventListener('mousemove', mouseMove);
    this.trafficSignPage.addEventListener('mouseup', mouseUp);

    this.setState({draggingDirectionRotation: true});
  }

  loadImage(blod) {
    return new Promise (function (resolved, rejected) {
      const reader = new FileReader();
      reader.readAsDataURL(blod); 
      reader.onloadend = function() {
        const base64data = reader.result;                
        resolved(base64data);
      }
    })
  }

  async setImageToTrafficSign(trafficSign, event) {
    const file = event.target.files[0];
    const fileType = file.type;
    const blob = file.slice(0, file.size, fileType);
    const formData = new FormData();
    formData.append('file', blob, file.name);
    const path = 'nevisign/' + trafficSign.get('id') + '/';
    try {
      await fetch(path, 'DELETE', null, fileType);
    } catch(error) {

    }
    await fetch(path, 'POST', formData, fileType);

    const editedTrafficSign = {
      id: trafficSign.get('id'),
      image: path + file.name
    }

    this.updateTrafficSign(editedTrafficSign);
  }

  toggleMarkBothSide() {
    const value = !this.state.markBothSide;
    this.setState({ markBothSide: value });
    localStorage.markBothSide = value;
  }

  toggleMakeCombineTrafficSign() {
    if (!this.state.makeCombineTrafficSign) {
      this.props.showNotice('Nyt luot yhdistelmää', 'Warning');
    }
    else {
      this.props.showNotice('Yhdistelmän luonti lopetettu', 'Warning');
    }

    this.setState({ makeCombineTrafficSign: !this.state.makeCombineTrafficSign });
  }

  async lockRoad() {
    const road = integerValue(prompt('Anna haluttu tie', ''), 0);

    if (road === 0) {
      return;
    }

    this.setState({ lockedRoad: road }, async () => {
      await this.setRoadRotation();
    });
  }

  clearLockedRoad() {
    this.setState({ lockedRoad: null }, async () => {
      await this.setRoadRotation();
    });
  }

  showPopup(ref, pos) {
    if (ref == null) return;
    ref.leafletElement.openPopup();
    const locationWithOffset = this.calculateOffsetCoordinates(pos[0], pos[1], 0, 90);
    this.map.leafletElement.setView([locationWithOffset.latitude, locationWithOffset.longitude], 17);
  }

  calculateOffsetCoordinates(latitude, longitude, offsetMetresX, offsetMetresY) {
    const R = 6371e3; // metres
    const newLatitude  = latitude  + (offsetMetresY / R) * (180 / Math.PI);
    const newLongitude = longitude + (offsetMetresX / R) * (180 / Math.PI) / Math.cos(latitude * Math.PI / 180);
    return {latitude: newLatitude, longitude: newLongitude};
  }

  render() {
    const position = [64.1, 26.5];
    const zoom = 6;

    return (
      <div ref={element => this.trafficSignPage = element} className={this.state.draggingDirectionRotation ? 'dragging' : ''}>
        <link rel="stylesheet" href="https://unpkg.com/leaflet@1.4.0/dist/leaflet.css"
              integrity="sha512-puBpdR0798OZvTTbP4A8Ix/l+A4dHDD0DGqYW6RQ+9jxkRFclaxxQb/SJAWZfWAkuyeQUytO7+7N4QKrDh+drA=="
              crossOrigin='' />
        <TopContractAndSiteSelect store={this.props.store} />
        <Map id="map" center={position} zoom={zoom} maxZoom={this.state.maxZoom} onClick={this.setMarker}
             ref={element => this.map = element} onDragStart={this.onDragStartMap}>
          <TileLayer url={this.state.mapTilesUrl}
                     attribution={this.state.mapTilesAttribution}
                     maxZoom={this.state.maxZoom} />
          { this.state.yourLaditude != null ? (
            <Marker position={[this.state.yourLaditude, this.state.yourLongitude]}
                    icon={new L.Icon({iconUrl: 'images/your_location.gif', iconSize: [18, 43],
                          iconAnchor: [9, 43],
                          popupAnchor: [null, -43]
                          })}
                    zIndexOffset={-1000}>
              <Popup>
                <span>{Math.round(this.state.yourLaditude * 100000) / 100000}, {Math.round(this.state.yourLongitude * 100000) / 100000}</span>
              </Popup>
            </Marker>
          ) : null }
          {
            !this.state.loadingTrafficSigns || !this.state.loadingTypes ? null : (
              <div className="center loader"></div>
            )
          }
          <TrafficSigns trafficSigns={this.props.trafficSigns}
                        selectedTrafficSign={this.state.selectedTrafficSign}
                        getImageIcon={this.getImageIcon}
                        getImageDimensions={this.getImageDimensions}
                        selectTrafficSign={this.selectTrafficSign}
                        dimensionsReady={this.state.dimensionsReady}
                        erectTrafficSign={this.erectTrafficSign}
                        raiseTrafficSign={this.raiseTrafficSign}
                        removeTrafficSign={this.confirmRemoveTrafficSign}
                        removeLatestSignType={this.confirmRemoveLatestSignType}
                        setTrafficSignAfterDrag={this.setTrafficSignAfterDrag}
                        copyTrafficSign={this.copyTrafficSign}
                        erectAgainTrafficSign={this.erectAgainTrafficSign}
                        setImageToTrafficSign={this.setImageToTrafficSign}
                        moveTrafficSign={this.moveTrafficSign}
                        goToTrafficSign={this.state.goToTrafficSign}
                        showPopup={this.showPopup} />
          { this.state.markerLaditude != null ? (
            <Marker position={[this.state.markerLaditude, this.state.markerLongitude]}
                    icon={new L.Icon({iconUrl: 'images/marker.gif', iconSize: [30, 30]
                    })}
                    draggable={true} onDragEnd={this.setMarkerAfterDrag}>
            </Marker>
          ) : null }
        </Map>
        <div id='top-area'>
          <TrafficSignSelect trafficSignTypes={this.props.trafficSignTypes}
                             newTrafficSign={this.newTrafficSign}
                             changeCategory={this.changeCategory}
                             selectedCategory={this.state.selectedCategory}
                             ownCategories={this.state.ownCategories}
                             newCategory={this.toggleNewCategory}
                             deleteCategory={this.confirmDeleteCategory}/>
          <button id='erect-all' onClick={this.confirmErectAllTrafficSigns}>
            Pystytä kaikki
          </button>
          <button id='raise-all' onClick={this.confirmRaiseAllTrafficSigns}>
            Poista kaikki
          </button>
          <label id='both-side-marking' className={this.state.markBothSide ? ' selected' : ''}>
            MERKITSE TIEN MOLEMMIN PUOLIN
            <input type='checkbox' checked={this.state.markBothSide}
                   onChange={this.toggleMarkBothSide}/>
          </label>
        </div>
        <label className={this.state.lockedLocation ? 'lock-location selected' : 'lock-location'}>
          SEURAA SIJAINTIASI
          <input type='checkbox' checked={this.state.lockedLocation}
                 onChange={this.toggleLockedLocation}/>
        </label>
        { this.state.markerLaditude != null || this.state.selectedTrafficSign != null ?
          <button id='clear-button' onClick={this.clearMarker}>
            Tyhjää valinta
          </button>
          :
          null
        }
        <NewCategory trafficSignTypes={this.props.trafficSignTypes}
                     newTrafficSign={this.newTrafficSign}
                     changeCategory={this.changeCategory}
                     selectedCategory={this.state.selectedCategory}
                     ownCategories={this.state.ownCategories}
                     show={this.state.showNewCategory}
                     toggle={this.toggleNewCategory}
                     newCategoryTrafficSigns={this.state.newCategoryTrafficSigns}
                     addTrafficSignToCategory={this.addTrafficSignToCategory}
                     removeTrafficSignFromCategory={this.removeTrafficSignFromCategory}
                     changeNewCategoryName={this.changeNewCategoryName}
                     newCategoryName={this.state.newCategoryName}
                     createNewCategory={this.createNewCategory}
                     toggleMakeCombineTrafficSign={this.toggleMakeCombineTrafficSign}
                     makeCombineTrafficSign={this.state.makeCombineTrafficSign}
                     combineTrafficSignInCategory={this.combineTrafficSignInCategory} />
        <ChooseDirection show={this.state.chooseDirection} selectDirection={this.selectDirection}
                         toggle={this.toggleChooseDirection} selectedType={this.state.selectedType}
                         rotation={this.state.chooseDirectionRotation} freeDirectionRotation={this.state.freeDirectionRotation}
                         setFreeRotation={this.setFreeRotation} unSetFreeRotation={this.unSetFreeRotation}
                         startRotateDirection={this.startRotateDirection} foundRoad={this.state.foundRoad}
                         changeFoundRoad={this.changeFoundRoad} loading={this.state.loadingRoad}
                         lockRoad={this.lockRoad} clearLockedRoad={this.clearLockedRoad}
                         lockedRoad={this.state.lockedRoad} />
      </div>
    );
  }
};

export default connect(state => ({
  trafficSigns: state.trafficSign.get('trafficSigns'),
  trafficSignTypes: state.trafficSign.get('trafficSignTypes'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
}), { addTrafficSign, changeTrafficSign, addTrafficSigns,
      removeTrafficSign, showNotice, showMessage, showConfirm })(TrafficSignPage);
