import { fromJS } from 'immutable';

export const initialState = fromJS({
  trafficSignTypes: [
    {name: '20 nopeusrajoitus', category: 1, icon: '1/20.gif'},
    {name: '30 nopeusrajoitus', category: 1, icon: '1/30.gif'},
    {name: '30 nopeusrajoitus päättyy', category: 1, icon: '1/30_end.gif'},
    {name: '40 nopeusrajoitus', category: 1, icon: '1/40.gif'},
    {name: '50 nopeusrajoitus', category: 1, icon: '1/50.gif'},
    {name: '50 nopeusrajoitus päättyy', category: 1, icon: '1/50_end.gif'},
    {name: '60 nopeusrajoitus', category: 1, icon: '1/60.gif'},
    {name: '60 nopeusrajoitus päättyy', category: 1, icon: '1/60_end.gif'},
    {name: '70 nopeusrajoitus', category: 1, icon: '1/70.gif'},
    {name: '80 nopeusrajoitus', category: 1, icon: '1/80.gif'},
    {name: '100 nopeusrajoitus', category: 1, icon: '1/100.gif'},
    {name: '120 nopeusrajoitus', category: 1, icon: '1/120.gif'},
    {name: '40 alue nopeusrajoitus', category: 1, icon: '1/40_area.gif'},
    {name: '40 alue nopeusrajoitus päättyy', category: 1, icon: '1/40_area_end.gif'},
    {name: 'Kaista kohtaiset rajoitukset', category: 1, icon: '1/lane_specifid_limits.gif'},
    {name: 'Ajoneuvolla ajo kielletty', category: 1, icon: '1/no_vehicles.gif'},
    {name: 'Moottori ajoneuvolla ajo kielletty', category: 1, icon: '1/no_motor_vehicles.gif'},
    {name: 'Kuorma-autolla ajo kielletty', category: 1, icon: '1/no_trucks.gif'},
    {name: 'Ajoneuvo yhdistelmillä ajo kielletty', category: 1, icon: '1/no_combination_vehicles.gif'},
    {name: 'Traktorilla ajo kielletty', category: 1, icon: '1/no_tractors.gif'},
    {name: 'moottoripyörällä ajo kielletty', category: 1, icon: '1/no_motorcycles.gif'},
    {name: 'Kelkalla ajo kielletty', category: 1, icon: '1/no_snowmobiles.gif'},
    {name: 'Vaarallisten aineiden kuljetus kielletty', category: 1, icon: '1/no_dangerous_substance.gif'},
    {name: 'Linja-autolla ajo kielletty', category: 1, icon: '1/no_busses.gif'},
    {name: 'Mopolla ajo kielletty', category: 1, icon: '1/no_mopeds.gif'},
    {name: 'Pyörällä ja mopolla ajo kielletty', category: 1, icon: '1/no_bikes_or_mopeds.gif'},
    {name: 'Jalankulku kielletty', category: 1, icon: '1/no_walking.gif'},
    {name: 'Jalankulku sekä pyörällä että mopolla ajo kielletty', category: 1, icon: '1/no_walking_bikes.gif'},
    {name: 'Ratsastus kielletty', category: 1, icon: '1/no_riding.gif'},
    {name: 'Väärä ajosuunta', category: 1, icon: '1/wrong_way.gif'},
    {name: 'Vasemmalle kääntyminen kielletty', category: 1, icon: '1/no_turning_left.gif'},
    {name: 'Oikealle kääntyminen kielletty', category: 1, icon: '1/no_turning_right.gif'},
    {name: 'U käännös kielletty', category: 1, icon: '1/no_u_turns.gif'},
    {name: 'Ajoneuvon suurin sallittu leveys 2,2 m', category: 1, icon: '1/max_width_2,2.gif'},
    {name: 'Ajoneuvon suurin sallittu korkeus 3,5 m', category: 1, icon: '1/max_height_3,5.gif'},
    {name: 'Ajoneuvon suurin sallittu pituus 10 m', category: 1, icon: '1/max_lenght_10.gif'},
    {name: 'Ajoneuvon suurin sallittu massa 6 tonnia', category: 1, icon: '1/max_weight_6.gif'},
    {name: 'Ajoneuvon suurin sallittu massa 8 tonnia', category: 1, icon: '1/max_weight_8.gif'},
    {name: 'Ajoneuvon suurin sallittu massa 12 tonnia', category: 1, icon: '1/max_weight_12.gif'},
    {name: 'Ajoneuvon suurin sallittu massa 25 tonnia', category: 1, icon: '1/max_weight_25.gif'},
    {name: 'Ajoneuvon suurin sallittu massa 30 tonnia', category: 1, icon: '1/max_weight_30.gif'},
    {name: 'Ajoneuvon suurin sallittu massa 35 tonnia', category: 1, icon: '1/max_weight_35.gif'},
    {name: 'Ajoneuvo yhdistelmän suurin sallittu massa 30 tonnia', category: 1, icon: '1/max_weight_30_2.gif'},
    {name: 'Ajoneuvo yhdistelmän suurin sallittu massa 50 tonnia', category: 1, icon: '1/max_weight_50.gif'},
    {name: 'Ajoneuvo yhdistelmän suurin sallittu massa 55 tonnia', category: 1, icon: '1/max_weight_55.gif'},
    {name: 'Ajoneuvo yhdistelmän suurin sallittu massa 60 tonnia', category: 1, icon: '1/max_weight_60.gif'},
    {name: 'Ajoneuvo yhdistelmän suurin sallittu massa 65 tonnia', category: 1, icon: '1/max_weight_65.gif'},
    {name: 'Ajoneuvo yhdistelmän suurin sallittu massa 70 tonnia', category: 1, icon: '1/max_weight_70.gif'},
    {name: 'Akselille suurin sallittu massa 8 tonnia', category: 1, icon: '1/max_weight_axle_8.gif'},
    {name: 'Akselille suurin sallittu massa 10 tonnia', category: 1, icon: '1/max_weight_axle_10.gif'},
    {name: 'Telille suurin sallittu massa 13 tonnia', category: 1, icon: '1/max_weight_truck_13.gif'},
    {name: 'Telille suurin sallittu massa 16 tonnia', category: 1, icon: '1/max_weight_truck_16.gif'},
    {name: 'Telille suurin sallittu massa 18 tonnia', category: 1, icon: '1/max_weight_truck_18.gif'},
    {name: 'Telille suurin sallittu massa 21 tonnia', category: 1, icon: '1/max_weight_truck_21.gif'},
    {name: 'Ohitus kielto', category: 1, icon: '1/no_passing.gif'},
    {name: 'Ohitus kielto päättyy', category: 1, icon: '1/no_passing_end.gif'},
    {name: 'Ohitus kielto rekoille', category: 1, icon: '1/no_passing_truck.gif'},
    {name: 'Ohitus kielto rekoille päättyy', category: 1, icon: '1/no_passing_truck_end.gif'},
    {name: 'Pysäyttäminen kielletty', category: 1, icon: '1/no_stopping.gif'},
    {name: 'Pysäköinti kielletty', category: 1, icon: '1/no_parking.gif'},
    {name: 'Pysäköinti kielletty alueella', category: 1, icon: '1/no_parking_area.gif'},
    {name: 'Pysäköinti kielletty alueella päättyy', category: 1, icon: '1/no_parking_area_end.gif'},
    {name: 'Taksiasema-alue', category: 1, icon: '1/taxi_area.gif'},
    {name: 'Taksin pysäyttämispaikka', category: 1, icon: '1/taxi_parking.gif'},
    {name: 'Pysäköinti kielletty parittomina päivinä', category: 1, icon: '1/no_parking_odd.gif'},
    {name: 'Pysäköinti kielletty parillisina päivinä', category: 1, icon: '1/no_parking_even.gif'},
    {name: 'Pakollinen pysäyttäminen tulli tarkastusta varten (Suomi/Englanti)', category: 1, icon: '1/customs_checkout.gif'},
    {name: 'Pakollinen pysäyttäminen tulli tarkastusta varten (Suomi/Venäjä)', category: 1, icon: '1/customs_checkout_2.gif'},
    {name: 'Pakollinen pysäyttäminen tulli tarkastusta varten (Suomi/Ruotsi)', category: 1, icon: '1/customs_checkout_3.gif'},
    {name: 'Pakollinen pysäyttäminen tarkastusta varten', category: 1, icon: '1/police_checkout.gif'},

    {name: 'Kiertotieopastus', category: 2, icon: '2/detour.gif'},
    {name: 'Ajoreittiopastus', category: 2, icon: '2/detour2.gif'},
    {name: 'Ajokaistaopastus', category: 2, icon: '2/line_guide.gif'},
    {name: 'Ajokaistaopastus matkalla', category: 2, icon: '2/line_guide_distance.gif', customText: 'bottom'},
    {name: 'Ajokaistaopastus (Rekka)', category: 2, icon: '2/line_guide_truck.gif'},
    {name: 'Ajokaistaopastus matkalla 2', category: 2, icon: '2/line_guide_distance_2.gif'},
    {name: 'Ajokaistaopastus 2', category: 2, icon: '2/line_guide_2.gif'},
    {name: 'Vaihda kaistaa', category: 2, icon: '2/change_lane.gif'},
    {name: 'Vaihda kaistaa 2', category: 2, icon: '2/change_lane_2.gif'},
    {name: 'Vaihda kaistaa (normaali)', category: 2, icon: '2/change_lane_blue.gif'},
    {name: 'Ajoväylän erkanemisviitta ylä', category: 2, icon: '2/place_off_top.gif', customText: 'left', customTextColor: 'white'},
    {name: 'Tienviitta', category: 2, icon: '2/place_guide.gif', customText: 'left', customTextColor: 'white'},
    {name: 'Tienviitta yksityinen', category: 2, icon: '2/place_guide_private.gif', customText: 'left', customTextColor: 'white'},
    {name: 'Kierto tienviitta', category: 2, icon: '2/place_guide_work.gif', customText: 'left'},
    {name: 'Paikallinen tienviitta', category: 2, icon: '2/place_guide_local.gif', customText: 'left'},
    {name: 'Moottoritienviitta', category: 2, icon: '2/place_guide_motorway.gif', customText: 'left', customTextColor: 'white'},
    {name: 'Ajoväylän erkanemisviitta', category: 2, icon: '2/place_off.gif', customText: 'left', customTextColor: 'white'},
    {name: 'Umpitie', category: 2, icon: '2/blocked_way.gif'},
    {name: 'Umpitie 2', category: 2, icon: '2/blocked_way_2.gif'},
    {name: 'Umpitie (Pyörä)', category: 2, icon: '2/blocked_way_bike.gif'},
    {name: 'Suositeltu nopeus', category: 2, icon: '2/recommended_speed.gif', customText: 'center', customTextColor: 'white'},
    {name: 'Paikannimi', category: 2, icon: '2/place.gif', customText: 'center', customTextColor: 'white'},
    {name: 'Lentoasema', category: 2, icon: '2/airport.gif'},
    {name: 'Autolautta', category: 2, icon: '2/car_ferry.gif'},
    {name: 'Tavara satama', category: 2, icon: '2/cargo_dock.gif'},
    {name: 'Teollisuus alue', category: 2, icon: '2/industrial_estate.gif'},
    {name: 'Rautatieasema', category: 2, icon: '2/railway_station.gif'},
    {name: 'linja-autoasema', category: 2, icon: '2/bus_station.gif'},
    {name: 'Tarkoitettu autoille', category: 2, icon: '2/intended_for_cars.gif'},
    {name: 'Tarkoitettu linja-autoille', category: 2, icon: '2/intended_for_busses.gif'},
    {name: 'Tarkoitettu paketti autoille', category: 2, icon: '2/intended_for_vans.gif'},
    {name: 'Tarkoitettu moottoripyörille', category: 2, icon: '2/intended_for_motorcycles.gif'},
    {name: 'Tarkoitettu mopoille', category: 2, icon: '2/intended_for_mopeds.gif'},
    {name: 'Tarkoitettu traktoreille', category: 2, icon: '2/intended_for_tracktors.gif'},
    {name: 'Tarkoitettu asuntovaunuille', category: 2, icon: '2/intended_for_trailers.gif'},
    {name: 'Tarkoitettu pyörille', category: 2, icon: '2/intended_for_bikes.gif'},
    {name: 'Tarkoitettu rekoille', category: 2, icon: '2/intended_for_trucks.gif'},
    {name: 'Tarkoitettu käveliöille', category: 2, icon: '2/intended_for_walking.gif'},
    {name: 'Tarkoitettu vammaisille', category: 2, icon: '2/intended_for_invalids.gif'},
    {name: 'Tarkoitettu vaarallisen aineen kuljetukseen', category: 2, icon: '2/intended_for_dangerous_transport.gif'},
    {name: 'Reitti jolla on portaat', category: 2, icon: '2/way_includes_stairs.gif'},
    {name: 'Reitti jolla on portaat (2)', category: 2, icon: '2/way_includes_stairs_2.gif'},
    {name: 'Reitti jolla ei ole portaita', category: 2, icon: '2/way_not_includes_stairs.gif'},

    {name: 'Sulku aita', category: 3, icon: '3/close_fence.gif'},
    {name: 'Sulku aita valoilla', category: 3, icon: '3/close_fence_with_traffic_lights.gif'},
    {name: 'Kartio', category: 3, icon: '3/cone.gif'},
    {name: 'Merkki', category: 3, icon: '3/mark.gif'},

    {name: 'Vaarallinen tienreuna', category: 4, icon: '4/dangerous_roadside.gif'},
    {name: 'Irtokiviä', category: 4, icon: '4/loose_stone.gif'},
    {name: 'Tietyö', category: 4, icon: '4/road_work.gif'},
    {name: 'Liukastie', category: 4, icon: '4/slippery_road.gif'},
    {name: 'Varoitus liikennevaloista', category: 4, icon: '4/traffic_lights.gif'},
    {name: 'Varoitus kaksisuuntaisesta tiestä', category: 4, icon: '4/two_way.gif'},
    {name: 'Epätasainentie', category: 4, icon: '4/uneven_road.gif'},
    {name: 'Mutka oikealle', category: 4, icon: '4/curve_to_right.gif'},
    {name: 'Mutka vasemmalle', category: 4, icon: '4/curve_to_left.gif'},
    {name: 'Mutkia josta ensinmäinen oikealle', category: 4, icon: '4/curvies_first_right.gif'},
    {name: 'Mutkia josta ensinmäinen vasemmalle', category: 4, icon: '4/curvies_first_left.gif'},
    {name: 'Jyrkkä alamäki', category: 4, icon: '4/steep_hill.gif'},
    {name: 'Jyrkkä ylämäki', category: 4, icon: '4/steep_hill_2.gif'},
    {name: 'Kapeneva tie', category: 4, icon: '4/narrowing_road.gif'},
    {name: 'Avattava silta', category: 4, icon: '4/opening_bridge.gif'},
    {name: 'Lautta laituri tai ranta', category: 4, icon: '4/danger_drop_to_water.gif'},
    {name: 'Liikenneruuhka', category: 4, icon: '4/traffic_jam.gif'},
    {name: 'Töyssyjä', category: 4, icon: '4/bump.gif'},
    {name: 'Suojatien ennakkovaroitus', category: 4, icon: '4/crossing_warning.gif'},
    {name: 'Lapsia', category: 4, icon: '4/children.gif'},
    {name: 'Pyöräilijöitä', category: 4, icon: '4/bikes.gif'},
    {name: 'Hiihtäjiä', category: 4, icon: '4/skiers.gif'},
    {name: 'Hirvieläimiä', category: 4, icon: '4/moose.gif'},
    {name: 'Poroja', category: 4, icon: '4/reindeer.gif'},
    {name: 'Risteys', category: 4, icon: '4/intersection.gif'},
    {name: 'Sivutienr isteys', category: 4, icon: '4/side_road_intersection.gif'},
    {name: 'Sivutien risteys (2)', category: 4, icon: '4/side_road_intersection_2.gif'},
    {name: 'Sivutien risteys (3)', category: 4, icon: '4/side_road_intersection_3.gif'},
    {name: 'Sivutien risteys (4)', category: 4, icon: '4/side_road_intersection_4.gif'},
    {name: 'Liikenneympyrä', category: 4, icon: '4/roundabout.gif'},
    {name: 'Rautiovaunu', category: 4, icon: '4/tram.gif'},
    {name: 'Rautatien tasoristeys ilman puomia', category: 4, icon: '4/train_without_boom.gif'},
    {name: 'Rautatien tasoristeus puomilla', category: 4, icon: '4/train.gif'},
    {name: 'Tasoristeys yhdellä raiteilla', category: 4, icon: '4/one_grade_crossing.gif'},
    {name: 'Tasoristeys kahdella tai useammalla raiteilla', category: 4, icon: '4/two_grade_crossing.gif'},
    {name: 'Putoavia kiviä', category: 4, icon: '4/falling_rocks.gif'},
    {name: 'Matalalla lentäviä lentokoneita', category: 4, icon: '4/air_planes_low.gif'},
    {name: 'Sivutuuli', category: 4, icon: '4/crosswind.gif'},
    {name: 'Muu vaara', category: 4, icon: '4/other_danger.gif'},

    {name: 'Kohde risteyksestä osoittamaan suuntaan', category: 5, icon: '5/on_left.gif'},
    {name: 'Kohde nuolen osoittamassa suunnassa', category: 5, icon: '5/target_at_left.gif'},
    {name: 'Kohde nuolen osoittamassa suunnassa matka', category: 5, icon: '5/target_at_left_distance.gif', customText: 'right'},
    {name: 'Voimassa mainitun matkan verran', category: 5, icon: '5/in_effect_distance.gif', customText: 'center'},
    {name: 'Lisäkilpi', category: 5, icon: '5/extra_tag.gif', customText: 'center'},
    {name: 'Matka pakolliseen pysäytykseen', category: 5, icon: '5/distance_to_stop.gif', customText: 'bottom'},
    {name: 'Vapaa leveys', category: 5, icon: '5/free_width.gif', customText: 'center'},
    {name: 'Vapaa korkeus', category: 5, icon: '5/free_height.gif', customText: 'center'},
    {name: 'Sähköjohdon korkeus', category: 5, icon: '5/free_height_electric.gif', customText: 'center'},
    {name: 'Vaikutus molempiin suuntiin', category: 5, icon: '5/effects_both_sides.gif'},
    {name: 'Vaikutus molempiin suuntiin (Pysty)', category: 5, icon: '5/effects_both_sides_2.gif'},
    {name: 'Vaikutus nuolen osoittamaan suuntaan', category: 5, icon: '5/effects_to.gif'},
    {name: 'Vaikutus alkaa', category: 5, icon: '5/effects_starts.gif'},
    {name: 'Vaikutus päättyy', category: 5, icon: '5/effects_ends.gif'},
    {name: 'Henkilöauto', category: 5, icon: '5/car.gif'},
    {name: 'Linja-auto', category: 5, icon: '5/bus.gif'},
    {name: 'Ajoneuvo yhdistelmä', category: 5, icon: '5/combination.gif'},
    {name: 'Kuorma-auto', category: 5, icon: '5/truck.gif'},
    {name: 'Pakettiauto', category: 5, icon: '5/van.gif'},
    {name: 'Matkailu ajoneuvo', category: 5, icon: '5/traveling_vehicle.gif'},
    {name: 'Invalidin ajoneuvo', category: 5, icon: '5/invalid_vehicle.gif'},
    {name: 'Moottoripyörä', category: 5, icon: '5/motorcycle.gif'},
    {name: 'Mopo', category: 5, icon: '5/moped.gif'},
    {name: 'Polkupyörä', category: 5, icon: '5/bike.gif'},
    {name: 'Pysäköinti tapa', category: 5, icon: '5/parking_way.gif'},
    {name: 'Pysäköinti tapa (2)', category: 5, icon: '5/parking_way_2.gif'},
    {name: 'Kielto a tyypin vaarallisten aineiden kuljetukselle', category: 5, icon: '5/no_a_type_cargo.gif'},
    {name: 'Kielto b tyypin vaarallisten aineiden kuljetukselle', category: 5, icon: '5/no_b_type_cargo.gif'},
    {name: 'Etuajo oikeutettu suunta', category: 5, icon: '5/right_of_way.gif'},
    {name: 'Etuajo oikeutettu suunta (2)', category: 5, icon: '5/right_of_way_2.gif'},
    {name: 'Tukkitie', category: 5, icon: '5/log_road.gif'},
    {name: 'Kaksi suuntainen pyörä tie', category: 5, icon: '5/two_way_bike.gif'},

    {name: 'Pakollinen ajosuunta (1)', category: 6, icon: '6/driving_direction.gif'},
    {name: 'Pakollinen ajosuunta (2)', category: 6, icon: '6/driving_direction_2.gif'},
    {name: 'Pakollinen ajosuunta (3)', category: 6, icon: '6/driving_direction_3.gif'},
    {name: 'Pakollinen ajosuunta (4)', category: 6, icon: '6/driving_direction_4.gif'},
    {name: 'Pakollinen ajosuunta (5)', category: 6, icon: '6/driving_direction_5.gif'},
    {name: 'Pakollinen ajosuunta (6)', category: 6, icon: '6/driving_direction_6.gif'},
    {name: 'Pakollinen ajosuunta (7)', category: 6, icon: '6/driving_direction_7.gif'},
    {name: 'Pakollinen ajosuunta (8)', category: 6, icon: '6/driving_direction_8.gif'},
    {name: 'Pakollinen ajosuunta (9)', category: 6, icon: '6/driving_direction_9.gif'},
    {name: 'traffic_split', category: 6, icon: '6/traffic_split.gif'},
    {name: 'traffic_split (2)', category: 6, icon: '6/traffic_split_2.gif'},
    {name: 'Jalkakäytävä', category: 6, icon: '6/pedestrian_road.gif'},
    {name: 'Pyörätie', category: 6, icon: '6/bike_road.gif'},
    {name: 'Yhdistetty jalkakäytävä ja pyörätie', category: 6, icon: '6/pedestrian_bike_road.gif'},
    {name: 'Jalkakäytävä ja pyörätie vierekkäin', category: 6, icon: '6/pedestrian_bike_split_road.gif'},
    {name: 'Jalkakäytävä ja pyörätie vierekkäin (2)', category: 6, icon: '6/pedestrian_bike_split_road_2.gif'},
    {name: 'Moottorikelkkailureitti', category: 6, icon: '6/snowmobile_road.gif'},
    {name: 'Ratsastus tie', category: 6, icon: '6/riding_road.gif'},

    {name: 'Etuajo-oikeutettu tie', category: 7, icon: '7/right_of_way.gif'},
    {name: 'Etuajo-oikeutettu tie päättyy', category: 7, icon: '7/right_of_way_end.gif'},
    {name: 'Etuajo-oikeus kohdattaessa', category: 7, icon: '7/priority_over_oncoming.gif'},
    {name: 'Västämis velvollisuus kohdattaessa', category: 7, icon: '7/ahead_has_right_of_way.gif'},
    {name: 'Västämis velvollisuus risteyksessä', category: 7, icon: '7/give_way.gif'},
    {name: 'Pakollinen pysäyttäminen', category: 7, icon: '7/stop.gif'},

    {name: 'Suojatie', category: 8, icon: '8/crossing.gif'},
    {name: 'Liityntäpysyköinti metro', category: 8, icon: '8/parking_metro.gif'},
    {name: 'Liityntäpysyköinti juna', category: 8, icon: '8/parking_train.gif'},
    {name: 'Liityntäpysyköinti bussi', category: 8, icon: '8/parking_bus.gif'},
    {name: 'Pysäköintipaikka', category: 8, icon: '8/parking_area.gif'},
    {name: 'Pysäköintipaikka (2)', category: 8, icon: '8/parking_area_2.gif'},
    {name: 'Pysäköintipaikka (3)', category: 8, icon: '8/parking_area_3.gif'},
    {name: 'Pysäköintipaikka (4)', category: 8, icon: '8/parking_area_4.gif'},
    {name: 'Kohtaamispaikka', category: 8, icon: '8/intersect_spot.gif'},
    {name: 'Paikallinen bussi pysäkki', category: 8, icon: '8/local_bus_stop.gif'},
    {name: 'Bussi pysäkki', category: 8, icon: '8/bus_stop.gif'},
    {name: 'Rautiovaunu pysäkki', category: 8, icon: '8/tram.gif'},
    {name: 'Taksi pysäkki', category: 8, icon: '8/taxi_stop.gif'},
    {name: 'Linja-auto kaista', category: 8, icon: '8/bus_line.gif'},
    {name: 'Linja-auto kaista (Taxi tekstillä)', category: 8, icon: '8/bus_taxi_line.gif'},
    {name: 'Linja-auto kaista päättyy', category: 8, icon: '8/bus_line_end.gif'},
    {name: 'Linja-auto kaista päättyy (Taxi tekstillä)', category: 8, icon: '8/bus_taxi_line_end.gif'},
    {name: 'Rautiovaunu kaista', category: 8, icon: '8/tram_line.gif'},
    {name: 'Rautiovaunu kaista (Taxi tekstillä)', category: 8, icon: '8/tram_taxi_line.gif'},
    {name: 'Rautiovaunu kaista päättyy', category: 8, icon: '8/tram_line_end.gif'},
    {name: 'Rautiovaunu kaista päättyy (Taxi tekstillä)', category: 8, icon: '8/tram_taxi_line_end.gif'},
    {name: 'Yksisuuntainen tie', category: 8, icon: '8/one_way.gif'},
    {name: 'Moottoritie', category: 8, icon: '8/motorway.gif'},
    {name: 'Moottoritie päättyy', category: 8, icon: '8/motorway_end.gif'},
    {name: 'Moottoritie päättyy matka', category: 8, icon: '8/motorway_end_distance.gif', customText: 'bottom'},
    {name: 'Moottori-liikenne tie', category: 8, icon: '8/motortrafficway.gif'},
    {name: 'Moottori-liikenne tie päättyy', category: 8, icon: '8/motortrafficway_end.gif'},
    {name: 'Tunneli', category: 8, icon: '8/tunnel.gif'},
    {name: 'Tunneli päättyy', category: 8, icon: '8/tunnel_end.gif'},
    {name: 'Hätä pysähtymis paikka', category: 8, icon: '8/emergency_stop.gif'},
    {name: 'Taajama', category: 8, icon: '8/conurbation.gif'},
    {name: 'Taajama päättyy', category: 8, icon: '8/conurbation_end.gif'},
    {name: 'Pihakatu', category: 8, icon: '8/yard.gif'},
    {name: 'Pihakatu päättyy', category: 8, icon: '8/yard_end.gif'},
    {name: 'Kävelykatu', category: 8, icon: '8/walking_way.gif'},
    {name: 'Kävelykatu päättyy', category: 8, icon: '8/walking_way_end.gif'},

    {name: 'Erkanemismerkki', category: 9, icon: '9/splitting.gif'},
    {name: 'Taustamerkki', category: 9, icon: '9/background.gif'},
    {name: 'Taustamerkki varalaskupaikalla', category: 9, icon: '9/background_2.gif'},
    {name: 'Kaarteen suunta', category: 9, icon: '9/curve_direction.gif'},
    {name: 'kiertotien suunta', category: 9, icon: '9/detour_direction.gif'},
    {name: 'Reunamerkki', category: 9, icon: '9/edge.gif'},
    {name: 'Reunamerkki (2)', category: 9, icon: '9/edge_2.gif'},
  ],
  trafficSigns: []
});

export default (state = initialState,
    action) => {
  switch (action.type) {
    case 'ADD_TRAFFIC_SIGN': {
      const existing = state.get('trafficSigns').find(trafficSign => trafficSign.get('id') === action.trafficSign.id);

      if (existing) {
        return state;
      }

      return state.set('trafficSigns', state.get('trafficSigns').push(fromJS(action.trafficSign)));
    }

    case 'CHANGE_TRAFFIC_SIGN': {
      const changingIndex = state.get('trafficSigns').findIndex(trafficSign => trafficSign.get('id') === action.trafficSign.id);

      if (state.get('trafficSigns').get(changingIndex) === fromJS(action.trafficSign)) {
        return state;
      }

      const newTrafficSigns = state.get('trafficSigns').set(changingIndex, fromJS(action.trafficSign));
      return state.set('trafficSigns', newTrafficSigns);
    }

    case 'REMOVE_TRAFFIC_SIGN': {
      const removableTrafficSign = state.get('trafficSigns').findIndex(trafficSign => trafficSign.get('id') === action.trafficSignId);

      if (removableTrafficSign !== -1) {
        return state.set('trafficSigns', state.get('trafficSigns').delete(removableTrafficSign));
      }

      return state;
    }

    case 'ADD_TRAFFIC_SIGNS': {
      return state.set('trafficSigns', fromJS(action.trafficSigns));
    }

    default: {
      return state;
    }
  }
};
